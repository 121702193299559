import React from "react";
import ABOUT from "../../assets/images/aBOUT.jpeg";

const AboutPage = () => {
  return (
    <>
      <div className="about_main_div">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-6 col-md-5 col-sm-12">
              <div className="text-center">
                <h1 className="h11_country_cscps ms-2 me-2 m-0 p-0">
                  ABOUT US
                </h1>
                <h1
                  className="text-white ace_span22 ms-2 me-2 m-0 p-0"
                  style={{ whiteSpace: "nowrap" }}
                >
              CRICKET REPUBLICS
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-0 pt-lg-5 pb-0 pb-lg-5">
        <div className="row mainPage">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h1 className="ace_h1_21 p-0 m-0">
              <span className="ace_span m-0 p-0">
                The Brand Story: TimeLine (1998-To date)
              </span>
            </h1>
            <h5 className="globalizedSty my-3 text-capitalize">
              <h4 className="ace_h4">
              CRICKET REPUBLICS
              </h4>
            </h5>
            <p className="pragraphStyMission mt-4">
              Conceived in <span className="fw-bold">1998</span>, the CRICKET REPUBLICS as a system took years to develop and kept changing with the advancement in technologies associated with learning. It underwent massive genesis with the advent of regenerative artificial intelligence, and in its current shape and form is all set to span the entirety of its timeline in the future.
              <h4 className="ace_span pt-3">Our Vision</h4> Our vision is to
              integrate artificial intelligence in medical and allied health
              education to produce world class health professionals of the
              future.
              <h4 className="ace_span pt-3">Our Mission</h4> Our mission is to
              make medical education accessible to everyone in the world and
              disrupt the current systems.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 aboutGlobeMainDiv">
            <div className="globalImg">
              <img
                src={ABOUT}
                alt="globalImg"
                className="rounded-3"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
