import React from "react";
import Logo from "../../assets/images/logo.png";
import {
  faceIconFooter,
  linkIconFooter,
  instIcon,
  twitIconFooter,
  locateIconFooter,
} from "../../constant/svgs";
import { Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const openEmail = () => {
    window.location.href = "mailto:hello@cricketrepublics.com";
  };
  return (
    <>
      <div className="footer_main_div">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row pt-5 pb-0 pb-lg-5">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="ps-3 pe-3 logo_shadow d-inline-flex">
                    <Link
                      className="text-decoration-none"
                      to="/"
                      onClick={scrollToTop}
                    >
                      <img
                        src={Logo}
                        alt="logo"
                        width={50}
                      />
                    </Link>
                  </div>
                  <p className="footer_para mt-4">
                  CRICKET REPUBLICS is a pioneering force in the global cricket industry, revolutionizing how the sport is played, managed, and monetized. With a unique blend of technology and passion, they operate a comprehensive ecosystem that spans player development, club management, and cricket-related commerce. Through our innovative SaaS platform, CRICKET REPUBLICS empowers players, clubs, and businesses to connect, compete, and thrive on a global scale. The company has set new standards in cricket management and engagement, and extends its gratitude to the investors, partners, and global cricket community who have embraced and supported this transformative vision.
                  </p>
                  <div className="d-flex align-items-center gap-3 mt-4 pt-2">
                    <div>
                      <Link target="blank" to="https://www.facebook.com/people/Cricket-Republics/61564328692287/?sfnsn=scwspwa&mibextid=RUbZ1f" className="circle-div">
                        {faceIconFooter}
                      </Link>
                    </div>
                    <div>
                      <Link to="/" className="circle-div">
                        {twitIconFooter}
                      </Link>
                    </div>
                    <div>
                      <Link to="/" className="circle-div">
                        {instIcon}
                      </Link>
                    </div>
                    <div>
                      <Link to="/" className="circle-div">
                        {linkIconFooter}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-6 d-none d-md-block">
                  <h4 className="ace_span pt-3">Quick Links</h4>
                  <Link
                    className="text-decoration-none"
                    to="/"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 mt-5 pt-2">Home</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/about"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1">About</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/contact"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">Contact</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/our-companies"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">
                      Our Companies
                    </p>
                  </Link>
                </div>
                <div className="col-lg-2 col-md-2 col-5 d-none d-md-block">
                  <h4 className="ace_span pt-3">Locations</h4>
                  <div className="row mt-2 mt-lg-5 pt-2">
                    <div className="col-12">
                      <p className="heading_decoration_none">Pakistan</p>
                      <div className="d-flex gap-2 align-content-center cursor_pointer">
                        <div className="d-flex align-items-start pt-1">
                          {locateIconFooter}
                        </div>
                        <p className="footer_para_2 w-75">
                          <Link
                            className="footer_para_2 text-decoration-none"
                          // to="https://maps.app.goo.gl/B3ESLSuHWy8pWb8n9"
                          // target="_blank"
                          >
                            32 A Lawrence Road, Jinnah Town, Lahore
                          </Link>
                        </p>
                      </div>
                      <p
                        onClick={openEmail}
                        className="footer_para_mail d-flex gap-2 align-items-center cursor_pointer"
                      >
                        <div>
                          <Mail size={16} color="white" />
                        </div> hello@cricketrepublics.com
                      </p>
                      <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                        <a
                          href="tel:+16034848016"
                          className="footer_para_2 text-decoration-none d-flex gap-2"
                        >
                          <Phone size={17} color="white" />
                          0321-9700700
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-5 d-none d-md-block">
                  <h4 className="ace_span pt-3">Locations</h4>
                  <div className="row mt-2 mt-lg-5 pt-2">
                    <div className="col-12">
                      <p className="heading_decoration_none">Pakistan</p>
                      <div className="d-flex gap-2 align-content-center cursor_pointer">
                        <div className="d-flex align-items-start pt-1">
                          {locateIconFooter}
                        </div>
                        <p className="footer_para_2 w-75">
                          <Link
                            className="footer_para_2 text-decoration-none"
                          // to="https://maps.app.goo.gl/B3ESLSuHWy8pWb8n9"
                          // target="_blank"
                          >
                            32 A Lawrence Road, Jinnah Town, Lahore
                          </Link>
                        </p>
                      </div>
                      <p
                        onClick={openEmail}
                        className="footer_para_mail d-flex gap-2 align-items-center cursor_pointer"
                      >
                        <div>
                          <Mail size={16} color="white" /></div> hello@cricketrepublics.com
                      </p>
                      <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                        <a
                          href="tel:+16034848016"
                          className="footer_para_2 text-decoration-none d-flex gap-2"
                        >
                          <Phone size={17} color="white" />
                          0321-9700700
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="footer_hr mt-3 mt-lg-0" />
        <div className="pb-2 pt-0 pt-lg-2">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 text-start mt-2 my-sm-auto text-lg-center text-md-center order-2 order-md-1">
              <p className="footer_para_4 mt-3 mt-lg-0 mt-md-0 m-md-auto p-0 ms-3 ms-lg-0">
                CRICKET REPUBLICS © 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
