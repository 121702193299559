import React, { useRef } from "react";
import Footer from "./component/footer/Footer";
import Navbar from "./component/navbar/Navbar";
import Test from "./Router";
import "./index.css";
import "./style.css";
import { useLocation } from "react-router-dom";

// BOOSTRAP-5 CDN
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const investorPortalRef = useRef(null);
  const franchisePortalRef = useRef(null);
  const advertisePortalRef = useRef(null);
  const ecommercePortalRef = useRef(null);
  const educationPortalRef = useRef(null);
  const blogsPortalRef = useRef(null);
  const seoPortalRef = useRef(null);
  const newsPortalRef = useRef(null);
  const partnerPortalRef = useRef(null);

  const location = useLocation();
  
  const scrollToSection = (section) => {
    switch (section) {
      case "investor-portal":
        investorPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "franchise-portal":
        franchisePortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "advertise-portal":
        advertisePortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "ecommerce-portal":
        ecommercePortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "players-portal":
        educationPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "blogs-portal":
        blogsPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "seo-portal":
        seoPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "clubs-portal":
        newsPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "partner-portal":
        partnerPortalRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  return (
    <>
      {!isDashboardRoute && <Navbar scrollToSection={scrollToSection} />}
      <Test
        investorPortalRef={investorPortalRef}
        franchisePortalRef={franchisePortalRef}
        advertisePortalRef={advertisePortalRef}
        ecommercePortalRef={ecommercePortalRef}
        educationPortalRef={educationPortalRef}
        blogsPortalRef={blogsPortalRef}
        seoPortalRef={seoPortalRef}
        newsPortalRef={newsPortalRef}
        partnerPortalRef={partnerPortalRef}
      />
      {!isDashboardRoute && (
        <div className="mt-5">
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
