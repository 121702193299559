import React, { useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { flags } from "../../constant/flags";
import { ArrowRightLeft } from "lucide-react";

const PortalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { portalType } = useParams();
  const { countryName, portalName, countryCode } = location.state || {};
  const [inputValue, setInputValue] = useState('');
  const [words, setWords] = useState([]);

  const redirectPage = (portalType) => {
    console.log(
      `Redirecting to form with countryName: ${countryName} and portalType: ${portalType}`
    );
    navigate(`/${portalType}-Form`, {
      state: {
        countryName: countryName,
      },
    });
  };

  const [modal, setModal] = useState({ visible: false, content: {} });

  const partners = [
    {
      name: 'Equity Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Individuals or entities who want to invest a significant amount of capital into the company for more than 5 years.',
      content2: 'Typically shall have voting rights and a say in major business decisions.',
      content3: 'Share in the profits and growth of the company, often receive dividends, and have long-term investment benefits.'
    },
    {
      name: 'Strategic Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Businesses or individuals that bring strategic value beyond just capital, such as expertise, market access, or technology.',
      content2: 'May have limited voting rights depending on their level of investment and involvement.',
      content3: 'Benefit from mutual growth, shared resources, and strategic advantages that help both parties achieve their business objectives.'
    },
    {
      name: 'Silent Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Investors who provide capital but do not take an active role in management or operations.',
      content2: 'Generally shall not have voting rights.',
      content3: 'Receive a portion of the profits based on their investment but remain passive in day-to-day business activities.'
    },
    {
      name: 'Limited Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Typically part of a limited partnership structure where they invest capital but have limited liability.',
      content2: 'No involvement in management and limited voting rights.',
      content3: 'Enjoy profits and returns on their investment with limited liability exposure.'
    },
    {
      name: 'General Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Involved in the management and operations of the business.',
      content2: 'Full voting rights and management responsibilities.',
      content3: 'Share in profits and have a significant influence over business decisions.'
    },
    {
      name: 'Corporate Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Other companies that collaborate for mutual benefits.',
      content2: 'Rights vary depending on the agreement.',
      content3: 'Gain from shared resources, joint ventures, and collaborative projects.'
    }
  ];

  const seoCategories = [
    {
      heading: 'On-Page SEO',
      points: [
        'Keyword Research',
        'Meta Tags Optimization',
        'Content Optimization',
        'URL Structure Optimization',
        'Internal Linking',
        'Image Optimization',
        'Mobile Optimization',
        'User Experience Improvements'
      ]
    },
    {
      heading: 'Off-Page SEO',
      points: [
        'Link Building',
        'Social Media Marketing',
        'Influencer Outreach',
        'Guest Blogging',
        'Content Marketing',
        'Online Reputation Management'
      ]
    },
    {
      heading: 'Technical SEO',
      points: [
        'Website Speed Optimization',
        'Mobile-Friendliness',
        'XML Sitemap Creation and Submission',
        'Robots.txt Optimization',
        'SSL Implementation',
        'Structured Data Markup',
        'Canonicalization',
        '404 Error Correction'
      ]
    },
    {
      heading: 'Local SEO',
      points: [
        'Google My Business Optimization',
        'Local Citations',
        'Local Link Building',
        'Reviews and Ratings Management',
        'Localized Content Creation',
        'NAP (Name, Address, Phone Number) Consistency'
      ]
    },
    {
      heading: 'E-commerce SEO',
      points: [
        'Product Page Optimization',
        'Category Page Optimization',
        'Schema Markup for Products',
        'Customer Reviews Integration',
        'Site Structure Optimization for E-commerce'
      ]
    },
    {
      heading: 'Content SEO',
      points: [
        'Blog Post Optimization',
        'Content Creation and Strategy',
        'Content Gap Analysis',
        'Evergreen Content Creation',
        'Content Audits'
      ]
    },
    {
      heading: 'SEO Audits',
      points: [
        'Comprehensive Site Audits',
        'Technical SEO Audits',
        'Content Audits',
        'Backlink Audits',
        'Competitor Analysis'
      ]
    },
    {
      heading: 'Voice Search SEO',
      points: [
        'Optimizing for Voice Search Queries',
        'Conversational Keyword Research',
        'Implementing Structured Data for Voice Search'
      ]
    },
    {
      heading: 'International SEO',
      points: [
        'Multilingual SEO',
        'Hreflang Tag Implementation',
        'Country-specific Search Engine Optimization'
      ]
    },
    {
      heading: 'Mobile SEO',
      points: [
        'Mobile Site Speed Optimization',
        'Mobile Usability',
        'Accelerated Mobile Pages (AMP)'
      ]
    },
    {
      heading: 'Video SEO',
      points: [
        'Video Content Optimization',
        'Video Metadata Optimization',
        'Video Thumbnails and Schema Markup',
        'YouTube SEO'
      ]
    },
    {
      heading: 'App Store Optimization (ASO)',
      points: [
        'App Title and Description Optimization',
        'Keyword Optimization for Apps',
        'App Reviews and Ratings Management',
        'App Download and Engagement Strategies'
      ]
    }
  ];

  const platformFeatures = [
    {
      heading: 'User-Friendly Interface',
      points: [
        'Our platform is designed for ease of use, allowing you to set up and manage your online store with no technical expertise required.',
        'Features intuitive navigation, simple product listing processes, and a streamlined checkout experience for your customers.'
      ]
    },
    {
      heading: 'Customizable Storefronts',
      points: [
        'Personalize your store’s look and feel with a variety of customizable templates and themes.',
        'Showcase your brand’s unique identity and attract customers with visually appealing designs.'
      ]
    },
    {
      heading: 'Secure Payment Solutions',
      points: [
        'Integrated with secure payment gateways to ensure safe transactions for you and your customers.',
        'Accept multiple payment methods, including credit/debit cards, digital wallets, and bank transfers.'
      ]
    },
    {
      heading: 'Comprehensive Inventory Management',
      points: [
        'Easily manage your products, track stock levels, and receive notifications for low inventory.',
        'Utilize powerful tools to categorize products, manage pricing, and apply discounts or promotions.'
      ]
    },
    {
      heading: 'Marketing and SEO Tools',
      points: [
        'Boost your store’s visibility with built-in SEO tools to optimize product listings and increase search engine rankings.',
        'Leverage marketing features like email campaigns, social media integration, and promotional banners to attract and retain customers.'
      ]
    },
    {
      heading: 'Analytics and Reporting',
      points: [
        'Gain valuable insights into your store’s performance with detailed analytics and reporting tools.',
        'Monitor sales, track customer behavior, and make data-driven decisions to grow your business.'
      ]
    },
    {
      heading: 'Customer Support',
      points: [
        'Access dedicated support to assist you with any questions or technical issues.',
        'Benefit from resources like tutorials, FAQs, and live chat to ensure your success.'
      ]
    }
  ];

  const ecommFeatures = [
    {
      heading: 'Sign Up',
      points: [
        'Register for an account on theCRICKET REPUBLICS E-commerce Portal.',
        'Complete the quick and easy sign-up process to access the platform.'
      ]
    },
    {
      heading: 'Set Up Your Store',
      points: [
        'Use our step-by-step setup wizard to create your store.',
        'Add your products, customize your storefront, and configure payment and shipping options.'
      ]
    },
    {
      heading: 'Launch and Sell',
      points: [
        'Launch your store and start selling to customers worldwide.',
        'Promote your store through various marketing channels and watch your business grow.'
      ]
    },
  ]

  const handleOpenModal = (partner) => {
    setModal({ visible: true, content: partner });
  };

  const handleCloseModal = () => {
    setModal({ visible: false, content: {} });
  };

  const handleChange1 = (event) => {
    const value = event.target.value;
    const wordCount = value.trim().split(' ').filter(word => word.length > 0).length;
    if (wordCount <= 5) {
      setInputValue(value);
    }
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const wordCount = inputValue.trim().split(' ').filter(word => word.length > 0).length;
    if (wordCount > 0 && wordCount <= 5) {
      setWords([...words, inputValue]);
      setInputValue('');
    } else {
      alert('Please enter 1 to 5 words only.');
    }
  };

  return (
    <>
      <div className="pakage_investor_back">
        <div className="d-block text-center">
          <h1 className="h11_country_cscps ms-2 me-2 m-0 p-0">{portalName}</h1>
          <h1
            className="text-white ace_span22 ms-2 me-2 m-0 p-0"
            style={{ whiteSpace: "nowrap" }}
          >
            &nbsp;{countryName}&nbsp;CRICKET REPUBLIC
            {portalType === "investor-portal" && (
              <span id="investor-portal">&nbsp;LTd.</span>
            )}
          </h1>
        </div>
      </div>
      <div className="container-fluid">
        {portalType === "investor-portal" && (
          <div className="container" id="investor-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      BUY YOUR PERMANENT SEAT <br />  Invest in&nbsp;{countryName}&nbsp;CRICKET REPUBLICS
                    </span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">A great investment opportunity guaranteeing highly sustainable and scalable income running into millions of dollars annually.
                      {/* with complete training to run public limited organization. Contribute directly to the cause of humanity and country’s development. */}
                    </h4>
                  </h5>
                  <p className="p2_country_cscps mt-3">
                    Ideal for business visionaries, medical professionals, educational institutions, private funds, investment banks, technology companies, foundations, and governments.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                  style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='row ms-2'>
              <div className='col-12'>
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">

                    Our Investors’ profile
                  </span>
                </h1>
                <p className="p2_country_cscps">
                  We have divided our investors into the following three types:
                </p>
                <ul>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Investors:</h4>
                    <div>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Small:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     $1,000-$50,000 for small investors
                      </p>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Medium:</span>&nbsp;&nbsp;&nbsp;     $ 50,000-1,000,000 for medium sized investors, and
                      </p>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Large:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    $ 1,000,000 to $ 100,000,000 dollars and above
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Partners:</h4>
                    <p className="p2_country_cscps ps-3">
                      Long-term partnerships with both voting and non-voting
                      rights
                    </p>
                  </li>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Franchises:</h4>
                    <p className="p2_country_cscps ps-3">
                      No capital injection required for franchise
                      opportunities.
                    </p>
                  </li>
                </ul>
                <p className="p2_country_cscps mt-3">
                  To apply in any of the above categories, please fill in the
                  relevant forms available in the respective sections of
                  Investment, Partners and Franchisees Portals on this website
                  for discussion, processing and approvals.
                </p>
                <button
                  onClick={() => {

                    redirectPage(portalType);
                  }}
                  className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                >
                  Become an Investor
                </button>
              </div>
            </div>
            <div className="row mainPage ms-2 mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">
                    Benefits of Investing with Us
                  </span>
                </h1>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Prestigious Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Gain shareholding in a leading brand known for excellence in
                  artificial intelligence, medicine, environmental and allied
                  health sciences, business administration, law, and more.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Solid Return on Investment:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Enjoy robust returns of 15-20% per annum starting after the
                  first two years, providing a secure financial future.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Transferable Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Ensure your legacy with fully transferable ownership rights to
                  your children or legally appointed heirs.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Permanent Founders Seat:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Secure a permanent founders seat at our institutions,
                  guaranteeing admission for your children and future
                  generations in any field of their choice.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Deferred Payment Options:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Benefit from deferred payment options on fees, with deductions
                  made from future profits, ranging from 10% to 100%.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Founders Board Membership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Join the prestigious Founders Board of any institution of your
                  choice, influencing future directions and decisions.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Elite Network Access:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Become part of an exclusive and powerful business and
                  professional network, opening doors to unparalleled
                  opportunities.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">
                    Long-Term Investment Opportunities:
                  </h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Engage in long-term investments with an average span of 2-3
                  years, ensuring stable growth and returns.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Short-Term Investment Benefits:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Short-term investors can take advantage of strategic
                  short-term returns on per share premiums, maximizing their
                  profits.
                </p>
              </div>
            </div>
          </div>
        )}
        {portalType === "franchise-portal" && (
          <div className="container" id="franchise-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the {countryName} Franchising Portal
                    </span>
                  </h1>
                  <h4 className="p2_country_cscps mt-4">
                    Enter Your Cities
                  </h4>
                  <div>
                    <form onSubmit={handleSubmit1}>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Cities Name"
                        value={inputValue}
                        onChange={handleChange1}
                      />
                      <button type="submit" className='drop_country p-1 ps-4 mt-3 pe-4'>ADD</button>
                    </form>
                    <div className='d-flex w-100'>
                      {words.map((word, index) => (
                        <p className='p2_country_cscps' key={index}>{word}, &nbsp;&nbsp;&nbsp;</p>
                      ))}
                    </div>
                  </div>
                  <h5 className="globalizedSty my-4 text-capitalize">
                    <h4 className="ace_h4">You will get the following:</h4>
                  </h5>
                  <ol>
                    <li className="p2_country_cscps">
                      Guaranteed profit of 15 to 35% or more
                    </li>
                    <li className="p2_country_cscps">
                      Guaranteed 100 seats allocation per location
                    </li>
                    <li className="p2_country_cscps">World class Curriculum</li>
                    <li className="p2_country_cscps">
                      Access to International Education Portal
                    </li>
                    <li className="p2_country_cscps">
                      Access to your own admin Portal
                    </li>
                    <li className="p2_country_cscps">
                      Rights to hold CRICKET REPUBLICS Conferences in your city
                    </li>
                    <li className="p2_country_cscps">
                      Online Classes with translation in your local language
                    </li>
                    <li className="p2_country_cscps">
                      Access to Examination Portal
                    </li>
                    <li className="p2_country_cscps">
                      Award of American Licensure Degree Programs byCRICKET REPUBLICS
                    </li>
                    <li className="p2_country_cscps">
                      CRICKET REPUBLICS Franchisee Passport
                    </li>
                  </ol>
                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Become a Franchisee
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                  style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "partner-portal" && (
          <div className="container" id="partner-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Become our partners in&nbsp;{countryName}&nbsp;CRICKET REPUBLICS
                    </span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">We have categorized our partners in the following 6 categories:

                    </h4>
                  </h5>
                  <p className="p2_country_cscps mt-3 fw-bold" style={{ color: "red" }}>
                    Please click on the headings below:
                  </p>
                  <div>
                    <ol>
                      {partners.map((partner, index) => (
                        <li
                          key={index}
                          className="ace_h4"
                          onClick={() => handleOpenModal(partner)}
                          style={{ cursor: "pointer", width: "280px" }}
                        >
                          {partner.name}
                        </li>
                      ))}
                    </ol>
                    {modal.visible && (
                      <div className="modal show">
                        <div className="modal-content modal_content">
                          <h5 className="fw-bold text-dark">{modal.content.heading1}</h5>
                          <p>{modal.content.content1}</p>
                          <h5 className="fw-bold text-dark">{modal.content.heading2}</h5>
                          <p>{modal.content.content2}</p>
                          <h5 className="fw-bold text-dark">{modal.content.heading3}</h5>
                          <p>{modal.content.content3}</p>
                          <span className="close" onClick={handleCloseModal}>&times;</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="p2_country_cscps mt-3">
                    To apply as a partner, please fill out the form below. <br /> For queries, email to hello@cricketrepublics.com for further processing.
                  </p>
                  <div className='mobile_button_center'>
                    <button
                      onClick={() => {

                        redirectPage(portalType);
                      }}
                      className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                    >
                      Become a Partner
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                  style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mainPage mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">
                    Benefits of Investing with Us
                  </span>
                </h1>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Prestigious Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Gain shareholding in a leading brand known for excellence in
                  artificial intelligence, medicine, environmental and allied
                  health sciences, business administration, law, and more.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Solid Return on Investment:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Enjoy robust returns of 15-20% per annum starting after the
                  first two years, providing a secure financial future.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Transferable Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Ensure your legacy with fully transferable ownership rights to
                  your children or legally appointed heirs.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Permanent Founders Seat:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Secure a permanent founders seat at our institutions,
                  guaranteeing admission for your children and future
                  generations in any field of their choice.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Deferred Payment Options:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Benefit from deferred payment options on fees, with deductions
                  made from future profits, ranging from 10% to 100%.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Founders Board Membership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Join the prestigious Founders Board of any institution of your
                  choice, influencing future directions and decisions.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Elite Network Access:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Become part of an exclusive and powerful business and
                  professional network, opening doors to unparalleled
                  opportunities.
                </p>

                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">
                    Long-Term Investment Opportunities:
                  </h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Engage in long-term investments with an average span of 2-3
                  years, ensuring stable growth and returns.
                </p>

                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Short-Term Investment Benefits:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Short-term investors can take advantage of strategic
                  short-term returns on per share premiums, maximizing their
                  profits.
                </p>
              </div>
            </div>
          </div>
        )}
        {portalType === "players-portal" && (
          <div className="container" id="players-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Cricket Players Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        Welcome to the Players Portal of Cricket Republics – your gateway to becoming a part of a global cricketing revolution! Whether you're an aspiring cricketer or a seasoned professional, this is the place where your journey begins. <br /><br />
                        By registering with us, you’ll gain access to exclusive opportunities that can elevate your career to the next level:
                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ul>
                        <li><span className='fw-bold'>Talent Showcases:</span> Be discovered by scouts and teams from around the world. Your skills could earn you a spot in prestigious leagues and tournaments.</li>
                        <br />  <li><span className='fw-bold'>Training Programs:</span> Get access to world-class training resources, expert coaching, and personalized development plans tailored to help you reach your peak performance.</li>
                        <br /> <li><span className='fw-bold'>Networking Opportunities:</span> Connect with fellow cricketers, coaches, and cricket enthusiasts globally. Build relationships that could lead to lifelong partnerships and career advancements.</li>
                        <br /> <li><span className='fw-bold'>Exclusive Events:</span> Receive invitations to participate in or attend exclusive events, including workshops, masterclasses, and cricket festivals.</li>
                        <br /> <li><span className='fw-bold'>Marketplace Access:</span> Get special deals on cricket gear, merchandise, and more through our affiliated partners.</li>
                      </ul>
                      <button
                        onClick={() => {

                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Register Now
                      </button>
                      <p className="p2_country_cscps mt-3">
                        Join the Cricket Republics community today and be part of the future of cricket. Register now and take the first step towards unlocking your full potential!
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                          style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "seo-portal" && (
          <div className="container" id="seo-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Cricket SEO Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        Welcome to the SEO Portal of Cricket Republics – your ultimate resource for mastering the digital landscape of cricket. Whether you’re a marketer, content creator, or cricket enthusiast looking to boost your online presence, this portal is designed to empower you with the tools and knowledge you need. <br /><br />
                        By registering with us, you’ll gain access to a suite of SEO resources tailored specifically for the cricket industry:

                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ul>
                        <li><span className='fw-bold'>Keyword Optimization:</span> Discover the most effective keywords to drive traffic to your cricket-related content. Stay ahead of the competition by targeting high-volume, relevant terms that resonate with cricket fans worldwide.</li>
                        <br /><li><span className='fw-bold'>Content Strategies:</span> Learn how to create compelling content that not only engages your audience but also ranks high on search engines. From blogs to social media posts, we provide the best practices to ensure your content gets noticed.</li>
                        <br /><li><span className='fw-bold'>Performance Analytics:</span> Get access to advanced analytics tools that help you track your website’s performance. Understand what works and refine your strategies for even greater success.</li>
                        <br /><li><span className='fw-bold'>Expert Guidance:</span> Benefit from insights and advice from leading SEO experts in the cricket domain. Our resources are designed to help you navigate the ever-changing landscape of search engine algorithms.</li>
                        <br /><li><span className='fw-bold'>Networking and Collaboration:</span> Connect with other SEO professionals, marketers, and cricket enthusiasts. Share strategies, collaborate on projects, and grow your digital presence together.</li>

                      </ul>
                      <button
                        onClick={() => {

                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Register Now
                      </button>
                      <p className="p2_country_cscps mt-3">
                        Join the Cricket Republic SEO Portal today and take control of your digital strategy. Register now to start optimizing your online presence and making an impact in the cricket world!
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                          style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "advertise-portal" && (
          <div className="container" id="advertise-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Cricket Advertise Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        Welcome to the Advertising Portal of Cricket Republic – your gateway to connecting with the global cricket community. Whether you're a brand seeking to reach passionate cricket fans or a business aiming to expand your reach, this portal offers tailored solutions to help you achieve your advertising goals.<br /><br />
                        Here’s what we provide:
                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ul>
                        <li><span className='fw-bold'>Reach Your Audience:</span> Access a targeted audience of cricket enthusiasts, players, and fans through our platform. Our advertising solutions are designed to connect you with the right people, ensuring maximum engagement and impact.</li>
                        <br /><li><span className='fw-bold'>Innovative Advertising Solutions:</span> Explore a range of advertising formats, including banner ads, sponsored content, and video ads, all crafted to capture the attention of the cricket community.</li>
                        <br /><li><span className='fw-bold'>Campaign Management:</span> Manage your advertising campaigns with ease using our intuitive tools. From planning to execution, our platform supports you every step of the way, ensuring your campaigns run smoothly.</li>
                        <br /><li><span className='fw-bold'>Performance Tracking:</span> Gain valuable insights into your ad performance with real-time analytics. Understand your audience, track engagement, and optimize your strategies to drive better results.</li>
                        <br /><li><span className='fw-bold'>Sponsorship Opportunities:</span> Take advantage of unique sponsorship opportunities within the cricketing world. Sponsor tournaments, events, or even individual players to enhance your brand’s visibility and credibility.</li>
                        <br /><li><span className='fw-bold'>Cheapest Rates:</span> Place your ads at the most competitive rates possible. We offer cost-effective advertising solutions that deliver maximum value, helping you reach your target audience without breaking the bank.</li>
                      </ul>
                      <button
                        onClick={() => {
                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Register Now
                      </button>
                      <p className="p2_country_cscps mt-3">
                        Join the Advertising Portal today and start making meaningful connections with the cricket community. Register now to launch your campaigns and see the difference! </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                          style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "clubs-portal" && (
          <div className="container" id="clubs-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Cricket Clubs Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        Welcome to the Cricket Clubs Portal of Cricket Republic – your one-stop destination for everything related to cricket clubs worldwide. Whether you’re managing a club, looking to join one, or simply interested in the club scene, this portal is designed to connect and support cricket clubs of all levels.<br /><br />
                        Here’s what our portal offers:
                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ul>
                        <li><span className='fw-bold'>Club Registration and Management:</span> Easily register your cricket club and manage all aspects of it through our intuitive platform. From member management to scheduling matches and events, streamline your club operations with ease.</li>
                        <br /><li><span className='fw-bold'>Player Recruitment:</span> Find and recruit talented players for your club. Our portal connects you with cricketers looking to join clubs, making it easier to build a strong and competitive team.</li>
                        <br /><li><span className='fw-bold'>Match and Event Coordination:</span> Organize and promote matches, tournaments, and events through our platform. Reach a wider audience and ensure your events are a success with our dedicated tools.</li>
                        <br /><li><span className='fw-bold'>Club Networking:</span> Connect with other cricket clubs locally and internationally. Share best practices, arrange friendly matches, and create a network that helps your club grow and thrive.</li>
                        <br /><li><span className='fw-bold'>Resources and Training:</span> Access a library of resources specifically tailored for cricket clubs, including coaching materials, club management tips, and training programs to help your club reach new heights.</li>
                        <br /><li><span className='fw-bold'>Exclusive Offers:</span> Benefit from special discounts and offers on cricket equipment, merchandise, and services through our partners. Equip your club with the best at competitive prices.</li>

                      </ul>
                      <button
                        onClick={() => {
                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Register Now
                      </button>
                      <p className="p2_country_cscps mt-3">
                        Join the Cricket Clubs Portal today and take your club to the next level. Register now to become part of a global community of cricket clubs dedicated to the love of the game!
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                          style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "ecommerce-portal" && (
          <div className="container" id="ecommerce-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Cricket Ecommerce Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        Welcome to the Cricket Ecommerce Portal of Cricket Republic – the world's first dedicated marketplace exclusively for cricket and cricketers alike. Whether you're a player, fan, or simply passionate about the sport, this portal is designed to bring the best of cricket to your fingertips. <br /><br />
                        Here’s what you can expect by joining our revolutionary platform:
                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ul>
                        <li><span className='fw-bold'>Exclusive Cricket Gear:</span> Access a wide range of cricket equipment, from bats and balls to protective gear, all from top brands and trusted suppliers. Whether you're a professional or an amateur, find everything you need to enhance your game.</li>
                        <br /><li><span className='fw-bold'>Custom Merchandise:</span> Explore unique and custom cricket merchandise that you won’t find anywhere else. From personalized jerseys to exclusive memorabilia, show your love for the game in style.</li>
                        <br /><li><span className='fw-bold'>Player-Driven Marketplace:</span> For cricketers, this is your chance to sell your own branded merchandise, share your signature products, or even launch your own cricket-related business. The Cricket Ecommerce Portal empowers you to turn your passion into profit.</li>
                        <br /><li><span className='fw-bold'>Training and Fitness Products:</span> Discover specialized training equipment, fitness tools, and dietary supplements that are specifically curated for cricketers. Stay in top shape and at the peak of your game with the right resources.</li>
                        <br /><li><span className='fw-bold'>Event and Tournament Access:</span> Purchase tickets for upcoming cricket events, tournaments, and exclusive player appearances directly through the portal. Stay connected to the cricketing world, both on and off the field.</li>
                        <br /><li><span className='fw-bold'>Community and Networking:</span> Engage with fellow cricket enthusiasts, players, and brands within a vibrant community. Share experiences, recommendations, and reviews to help others find the best products and services.</li>

                      </ul>
                      <button
                        onClick={() => {
                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Register Now
                      </button>
                      <p className="p2_country_cscps mt-3">
                        Join the Cricket Ecommerce Portal today and experience the future of cricket shopping. Register now and be part of a global community that celebrates the game like never before!
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                          style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "blogs-portal" && (
          <div className="container" id="blogs-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the {countryName} Blogging
                      Portal
                    </span>
                  </h1>
                  <p className="p2_country_cscps mt-3">
                    You can now create your legacy by contributing on a
                    dedicated page assigned to you on this platform. You
                    can choose form a wide variety of topics or simply
                    create your own topic if we have missed.
                  </p>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Benefits and Advantages:
                    </h4>
                  </h5>
                  <ol>
                    <li>Worldwide recognition in the medical and allied fields</li>
                    <li>Give your opinion a voice</li>
                    <li>Whatever you choose will appear on your country’s page with your name indexed on a bloggers page from that country</li>
                    <li>We offer blogs marketing services</li>
                    <li>Posting on your country’s page is free</li>
                    <li>If you want your logs to appear worldwide, use our premium logging service for $10.00, and it will show on every country’s page</li>
                    <li>Blogs can also be placed on the advertising portal to talk to your clients or position yourself in the market</li>
                    <li>All blogs are placed in the order they are received. The oldest remain on the top. To keep your ad on the top, simply opt for a premium placement and maintain the top slot on monthly basis.</li>
                    <li>For queries, please send us an email at hello@cricketrepublics.com</li>
                  </ol>
                  <Link to="/blogs-home-page" className='text-decoration-none'>
                    <button
                      className="eye_btn mt-3 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                    >
                      All Blogs
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                  style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="box_portals">
                      <h2 className="h3_home_cscps pt-3 text-center">Starter</h2>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          $ 50 a month
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          50 Countries
                        </h5>
                      </div>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Premium Placing
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Please add $100
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center pb-4 mt-3">
                        <Link className="text-decoration-none" to="/blogs-home-page">
                          <button
                            className="drop_country rounded p-2 ps-4 pe-4"
                            type="submit"
                          >
                            Buy Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box_portals">
                      <h2 className="box_portals_h3 pt-3 text-center">Economy</h2>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          $ 80 a month
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          80 Countries
                        </h5>
                      </div>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Premium Placing
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Please add $100
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center pb-4 mt-3">
                        <Link className="text-decoration-none" to="/blogs-home-page">
                          <button
                            className="drop_country rounded p-2 ps-4 pe-4"
                            type="submit"
                          >
                            Buy Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box_portals">
                      <h2 className="h3_home_cscps pt-3 text-center">
                        Super Economy
                      </h2>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          $ 150 a month
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          All countries
                        </h5>
                      </div>
                      <div className="d-flex justify-content-around align-items-center">
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Premium Placing
                        </h5>
                        <ArrowRightLeft />
                        <h5 className="hero_main_h5_1 my-3 text-center">
                          Please add $100
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center pb-4 mt-3">
                        <Link className="text-decoration-none" to="/blogs-home-page">
                          <button
                            className="drop_country rounded p-2 ps-4 pe-4"
                            type="submit"
                          >
                            Buy Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType !== "investor-portal" &&
          portalType !== "franchise-portal" &&
          portalType !== "partner-portal" &&
          portalType !== "advertise-portal" &&
          portalType !== "blogs-portal" &&
          portalType !== "clubs-portal" &&
          portalType !== "ecommerce-portal" &&
          portalType !== "seo-portal" &&
          portalType !== "players-portal" && (
            <div id="another">
              <h1 className="pt-5 ace_h1_21 p-0 m-0">Still Working</h1>
            </div>
          )}
      </div>
    </>
  );
};

export default PortalPage;
