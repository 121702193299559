import React from 'react';

const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="custom-alert-overlay">
            <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-md-6 col-12'>
                        <div className="custom-alert-box">
                            <h5 className="globalizedSty my-3 text-capitalize">
                                <h1 className="ace_h1_21 p-0 m-0 text-start">
                                    <span className="ace_span m-0 p-0">
                                        Thank You !
                                    </span>
                                </h1>
                            </h5>
                            <p className="custom-alert-message">{message}</p>
                            <div className='d-flex justify-content-center'>
                                <button className="eye_btn33 rounded-3 p-2 ps-4 pe-4" onClick={onClose}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomAlert;
