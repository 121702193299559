import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from "../assets/images/logo.png";

const DashboardSidebar = () => {
    return (
        <div className="sidebar bg-light">
            <div className="d-flex gap-4 justify-content-center pt-3 m-0">
                <div className='p-0 m-0'>
                    <img src={Logo} alt="Logo" width={50} />
                </div>
                <div className='m-0 p-0'>
                    <p className='ace_h1_101 p-0 m-0'>
                       CRICKET REPUBLICS
                    </p>
                </div>
            </div>
            <hr style={{ color: "white" }} className='p-0 m-0' />
            <div>
                <p className="ace_h1_103 p-0 m-0 text-center">
                    Admin Portal
                </p>
            </div>
            <hr style={{ color: "white" }} className='p-0 m-0' />
            <ul className="nav flex-column first mt-3">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard/tab1" activeClassName="active">Contact List</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard/tab2" activeClassName="active">Programs List</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard/tab3" activeClassName="active">Blog List</NavLink>
                </li>
            </ul>
        </div>
    );
};

export default DashboardSidebar;
