import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import portalSectionContent from "./portalSectionContent";
import CountryDropdown from "./CountryDropdown";
import { Link } from "react-router-dom";

const PortalSections = ({
  investorPortalRef,
  franchisePortalRef,
  advertisePortalRef,
  ecommercePortalRef,
  educationPortalRef,
  blogsPortalRef,
  seoPortalRef,
  newsPortalRef,
  partnerPortalRef,
}) => {
  const [color, setColor] = useState("#fe0000");

  useEffect(() => {
    const interval = setInterval(() => {
      setColor((prevColor) => (prevColor === "#fe0000" ? "white" : "white"));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <>
      {portalSectionContent.map((item, index) => (
        <div
          key={index}
          ref={
            item.portalType === "investor-portal"
              ? investorPortalRef
              : item.portalType === "franchise-portal"
                ? franchisePortalRef
                : item.portalType === "advertise-portal"
                  ? advertisePortalRef
                  : item.portalType === "ecommerce-portal"
                    ? ecommercePortalRef
                    : item.portalType === "players-portal"
                      ? educationPortalRef
                      : item.portalType === "blogs-portal"
                        ? blogsPortalRef
                        : item.portalType === "seo-portal"
                          ? seoPortalRef
                          : item.portalType === "clubs-portal"
                            ? newsPortalRef
                            : item.portalType === "partner-portal"
                              ? partnerPortalRef
                              : null
          }
          className={`${item.classBackgroundImage} ms-1 me-1 ms-lg-4 me-lg-4 mb-5`}
        >
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-9 col-12">
                <div>
                  <div
                    className="m-0 p-0 pt-2 pb-2 text-center investor_cscps_h1"
                    style={{ color }}
                  >
                    <div className="reacttyped_heading">
                      <ReactTyped
                        strings={[item.portalName]}
                        typeSpeed={40}
                        backSpeed={20}
                        loop
                        backDelay={10000}
                      />
                    </div>
                  </div>
                  {item.portalType === "medical-portal" ? (
                    <div className="d-flex justify-content-center mt-3">
                      <Link className="text-decoration-none" to={item.btnLink}>
                        <button className="eye_btn" onClick={scrollToTop}>
                          Create Your Blog
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className="mt-3">
                        <CountryDropdown
                          portalType={item.portalType}
                          portalName={item.portalName}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PortalSections;
