import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as images from "../../constant/images";

const Navbar = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (section) => {
    navigate("/");
    setTimeout(() => {
      window.location.hash = section;
      scrollToSection(section);
    }, 500);
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-light"
        style={{ borderBottom: "1px solid #ADAEAF" }}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={images.Logo} alt="logo" width={60} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item ps-2 pe-2">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              {location.pathname === "/" && (
                <li className="nav-item ps-2 pe-2 dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our Portals
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("investor-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Investor Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("franchise-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Franchise Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("partner-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Partner Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("advertise-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Advertising Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("clubs-portal")}
                        style={{ cursor: "pointer" }}
                      >
                    Cricket Clubs Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("players-portal")}
                        style={{ cursor: "pointer" }}
                      >
                    Players Portal
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("seo-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        SEO Portal
                      </span>
                    </li>
                    {/* <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("clubs-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Medical News Portal
                      </span>
                    </li> */}
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => scrollToSection("ecommerce-portal")}
                        style={{ cursor: "pointer" }}
                      >
                        Ecommerce Portal
                      </span>
                    </li>
                  </ul>
                </li>
              )}

              <li className="nav-item ps-2 pe-2">
                <Link className="nav-link" aria-current="page" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item ps-2 pe-2 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Shop
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <span
                      className="dropdown-item"
                      onClick={() => handleClick("ecommerce-portal")}
                      style={{ cursor: "pointer" }}
                    >
                      Ecommerce Portal
                    </span>
                  </li>
                </ul>
              </li>
              <li className="nav-item ps-2 pe-2">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/our-companies"
                >
                  Our Companies
                </Link>
              </li>
              <li className="nav-item ps-2 pe-2 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Registration
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/signup">
                      Sign Up
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/signin">
                      Sign In
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ps-2 pe-2">
                <Link className="nav-link" aria-current="page" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <Link className="text-decoration-none" to="/signin">
                <button className="eye_btn rounded p-2 ps-4 pe-4" type="button">
                  Dashboard
                </button>
              </Link>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
