import AdvertiseCountryDropdown from "../advertise/AdvertiseCountryDropdown";
import CountryDropdown from "./CountryDropdown";

const portalSectionContent = [
  {
    portalType: "partner-portal",
    classBackgroundImage: "partner_cscps_main_div",
    portalName: "PARTNERSHIP PORTAL",
    description: "Welcome to the Partner Portal. Explore our partnerships.",
    btnLink: "/partner-portal",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "INVESTORS PORTAL",
    classBackgroundImage: "investor_cscps_main_div",
    portalType: "investor-portal",
    countryComponent: <CountryDropdown />,
    description:
      "This is a once in a lifetime opportunity for the right investors looking for the greatest ROIs.",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "FRANCHISING PORTAL",
    classBackgroundImage: "franchise_cscps_main_div",
    portalType: "franchise-portal",
    countryComponent: <CountryDropdown />,
    description:
      "Our ideal franchisees are those who understand the value of the brand we are offering.",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "ADVERTISING PORTAL",
    classBackgroundImage: "advertise_cscps_main_div",
    portalType: "advertise-portal",
    countryComponent: <AdvertiseCountryDropdown />,
    description:
      "Our advertising portal is positioned to become largest of its kind in the health sector and associated industries.",
  },
  // {
  //   title: "command & staff college of physicians & surgeons",
  //   portalName: "BLOGS PORTAL",
  //   classBackgroundImage: "blog_cscps_main_div",
  //   portalType: "blogs-portal",
  //   countryComponent: <CountryDropdown />,
  //   btnLink: "/blogs-home-page",
  //   description:
  //     "Our blogging portal allows publishing of articles, reports, books, researches, etc.",
  // },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "PLAYERS PORTAL",
    classBackgroundImage: "eductaion_cscps_main_div",
    portalType: "players-portal",
    description: "Register to become teachers and students alike.",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "CRICKET SEO PORTAL",
    classBackgroundImage: "seo_cscps_main_div",
    portalType: "seo-portal",
    countryComponent: <CountryDropdown />,
    description:
      "We are very powerful with SEO, and inviting all companies to use this mega portal for their SEO needs.",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "CRICKET CLUBS PORTAL",
    classBackgroundImage: "news_cscps_main_div",
    portalType: "clubs-portal",
    countryComponent: <CountryDropdown />,
    description:
      "Publish blogs, articles, research papers, or start your own magazine.",
  },
  {
    title: "command & staff college of physicians & surgeons",
    portalName: "ECOMMERCE PORTAL",
    classBackgroundImage: "ecommerce_cscps_main_div",
    portalType: "ecommerce-portal",
    countryComponent: <CountryDropdown />,
    description:
      "Define your product, services, and ideas to launch your own ecommerce portal.",
  },
];

export default portalSectionContent;
